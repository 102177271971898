import i18n from '@/i18n';

const AUTHENTICATION_ERROR = 'Authentication error, try refreshing the page'; // $t('Errors.authorization');
const AUTHORIZATION_ERROR = 'Authorization error, try refreshing the page.';
const NETWORK_ERROR = 'Network error, check your internet connection.';
const SERVER_ERROR = 'Server error, there was an error on the server, try again later.';
const DEFAULT_ERROR = 'An error occured';

const BOOKING_ERROR = {
  ZoneAvailable: i18n.t('Errors.ZoneAvailable'),
  InFuture: i18n.t('Errors.InFuture'),
  Length: i18n.t('Errors.Length'),
  NoCurrentPerUser: i18n.t('Errors.NoCurrentPerUser'),
  NoFutureBookings: i18n.t('Errors.NoFutureBookings'),
  NoDaysInFuture: i18n.t('Errors.NoDaysInFuture'),
  NoPastBookings: i18n.t('Errors.cannot_book_in_past'),
};

const requestError = (statusCode) => {
  switch (statusCode) {
    case 401: return AUTHENTICATION_ERROR;
    case 403: return AUTHORIZATION_ERROR;
    case 'ERR_CONNECTION_REFUSED': return NETWORK_ERROR;
    case 500: return SERVER_ERROR;
    case 501: return SERVER_ERROR;
    default: return DEFAULT_ERROR;
  }
};

class ErrorMessage {
  static getMessageForRequestError(statusCode) {
    return requestError(statusCode);
  }

  static getRequestError(error = null) {
    if (error?.status === 400 && error.data.Message) {
      return error.data.Message;
    }
    if (error?.status) {
      return this.getMessageForRequestError(error.status);
    } return DEFAULT_ERROR;
  }

  static getBookingError(error) {
    let failedValidation;
    let eMsg;
    if (error.Code && error.Code === 'E_RULE_VALIDATION') {
      failedValidation = error.Messages.find((msg) => msg.Valid === false);
    }
    if (failedValidation?.Name) {
      eMsg = BOOKING_ERROR[failedValidation.Name];
    }
    if (Object.values(BOOKING_ERROR).includes(error?.message)) {
      eMsg = error.message;
    }
    if (!eMsg) {
      eMsg = requestError(error.status);
    }

    return eMsg;
  }
}

export default ErrorMessage;
