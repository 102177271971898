// In order to share booking template between views

const booking = {
  Attendees: null,
  CheckIn: null,
  Desc: null,
  From: null,
  Owner: null,
  Private: null,
  Source: null,
  Subject: null,
  Until: null,
  Zid: null,
  templateFor: null, // Which view is using the template.
  expandCustomBooking: null,
};

export default booking;
