import { issueStatusColors } from '@/classes/issue/issue';
import ExtendedDate from '../classes/extended.date.class';

export const getBookingsMatrix = (bookings, weekLength) => {
  const datesValidation = new Set();
  const bm = [[], [], [], [], [], [], []];
  bookings.forEach((booking) => {
    const date = new ExtendedDate(booking.From).getDate();
    datesValidation.add(date);
    const ix = (new ExtendedDate(booking.From).getDay() + 6) % 7;
    bm[ix].push(booking);
  });
  if (datesValidation.size > weekLength) throw new Error('calendar.view we have bookings with more different dates than the week is supposed to be long');
  return bm;
};

/* Below are functions for displaying notifications in the issue tracker */
export const getIssuesMatrix = (issues) => {
  const datesValidation = new Set();
  const issueMatrix = [[], [], [], [], [], [], []];
  issues.forEach((issue) => {
    const date = new ExtendedDate(issue.createdat).getDate();
    datesValidation.add(date);
    const ix = (new ExtendedDate(issue.createdat).getDay() + 6) % 7;
    issueMatrix[ix].push(issue);
  });
  return issueMatrix;
};

export const getBookingNotifications = (bookings, weekLength) => {
  const notifications = [];
  getBookingsMatrix(bookings, weekLength).forEach((arr) => {
    if (arr.length) notifications.push(['resource']);
    else notifications.push([]);
  });
  return notifications;
};

export const getIssueNotifications = (issues) => {
  // eslint-disable-next-line object-curly-newline
  const issueStatusOrder = { OPEN: 0, IN_PROGRESS: 1, RESOLVED: 2, CLOSED: 3 };
  const notifications = [[], [], [], [], [], [], []];
  getIssuesMatrix(issues).forEach((arr, index) => {
    let lastStatus = null;
    arr.forEach((issue) => {
      if (!lastStatus || issueStatusOrder[lastStatus] > issueStatusOrder[issue.status]) {
        notifications[index] = [issueStatusColors[issue.status]];
        lastStatus = issue.status;
      }
    });
  });
  return notifications;
};
