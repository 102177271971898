<template>
  <div v-if="TimepickerController.hasLoadedOnce">
    <TimepickerHeader
      class="header"
      :start-time="headerStartTime"
      :end-time="headerEndTime"
    />

    <div>
      <AccordionButton
        v-if="TimepickerController.canShowEarlierTimes()"
        class="accbutton top"
        :open="false"
        :arrow-direction="'up'"
        :closed-title="$t('Components.AccordionButton.show_earlier_time')"
        @clicked="accordionEarlierClicked"
      />

      <TimepickerTimeslots
        v-if="TimepickerController.hasLoadedOnce"
        :timepicker-controller="TimepickerController"
      />

      <AccordionButton
        v-if="TimepickerController.canDisplayMoreItems()"
        class="accbutton bottom"
        :open="false"
        :closed-title="$t('Components.AccordionButton.show_later_time')"
        @clicked="accordionLaterClicked"
      />
    </div>
  </div>
</template>

<script>

import ExtendedDate from '@/classes/extended.date.class';
import AccordionButton from '@/components/button/accordion.button.vue';
import TimepickerHeader from './timepicker.header.vue';
import TimepickerTimeslots from './timepicker.timeslots.vue';
import TimepickerController from './timepicker.controller';

export default {
  components: {
    AccordionButton,
    TimepickerHeader,
    TimepickerTimeslots,
  },
  props: {
    startDate: {
      type: ExtendedDate,
      required: false,
      default: new ExtendedDate(),
    },
    endDate: {
      type: ExtendedDate,
      required: false,
      default: undefined,
    },
    editBid: {
      type: Number,
      required: false,
      default: null,
    },
    zid: {
      type: Number,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      TimepickerController,
      isIniting: false,
    };
  },
  computed: {
    headerStartTime() {
      return TimepickerController.headerStartTime();
    },
    headerEndTime() {
      return TimepickerController.headerEndTime();
    },
  },
  watch: {
    async startDate(newValue) {
      await this.initTimepicker(newValue, this.endDate || undefined, this.zid);
    },
    async endDate(newValue) {
      await this.initTimepicker(this.startDate, newValue || undefined, this.zid);
    },
  },
  destroyed() {
    TimepickerController.reset();
  },
  async created() {
    await this.initTimepicker(this.startDate, this.endDate || undefined, this.zid);
  },
  methods: {
    accordionEarlierClicked(value) { TimepickerController.toggleEarlierTimeslots(value); },
    accordionLaterClicked(value) { TimepickerController.toggleLaterTimeslots(value); },
    async initTimepicker(from, until, zid) {
      if (!this.isIniting) {
        this.isIniting = true;
        await TimepickerController.init(from, until, zid, 16, false, this.editBid);
        this.isIniting = false;
      }
    },
  },
};

</script>

<style lang="scss" scoped>

  .header {
    margin-bottom: 2rem;
  }

  .accbutton {
    margin: 0 0 0 auto;

    &.top {
      margin-bottom: 1.5rem;
    }

    &.bottom {
      margin-top: 1.5rem;
    }
  }

</style>
