// Issue remains a clone of what xtunnel/v1/issue/:id returns
export class Issue {
  constructor(obj = null) {
    this.id = null;
    this.description = null;
    this.authorid = null;
    this.authorname = null;
    this.authoremail = null;
    this.status = null;
    this.types = null;
    this.companyid = null;
    this.zoneid = null;
    this.floorid = null;
    this.createdat = null;
    this.updatedat = null;
    this.deletedat = null;
    this.pinned = null;
    this.assignees = null;
    if (obj) Object.assign(this, obj);
  }

  toPartial() {
    return (({
      description, authorid, companyid, floorid, zoneid, status, types, pinned,
    }) => ({
      description, authorid, companyid, floorid, zoneid, status, types, pinned,
    }))(this);
  }

  pushAssignee(userid) {
    if (Array.isArray(this.assignees) && !this.assignees.includes(userid)) {
      this.assignees.push(userid);
    }
  }

  deleteAssignee(userid) {
    if (Array.isArray(this.assignees)) {
      this.assignees.splice(this.assignees.indexOf(userid), 1);
    }
  }
}

export const issueStatusColors = {
  OPEN: 'var(--component-red)',
  IN_PROGRESS: 'var(--component-amber)',
  RESOLVED: 'var(--component-green)',
  CLOSED: 'var(--component-gray)',
};

export const issuePastelColors = {
  OPEN: 'var(--component-red-pastel)',
  IN_PROGRESS: 'var(--component-amber-pastel)',
  RESOLVED: 'var(--component-green-pastel)',
  CLOSED: 'var(--component-gray-pastel)',
};

export const issueStatusSortingOrder = [
  'OPEN', 'IN_PROGRESS', 'RESOLVED', 'CLOSED',
];
